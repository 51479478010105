.icon-pulse {
  fa-spin: 1s infinite steps(8);
}
.icon-spin {
  animation: spin 2s linear infinite;
}
.spinClass {
  animation-name: spinTest;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  filter: invert(0.5) sepia() saturate(10000%) hue-rotate(210deg) brightness(2);
}
@-moz-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@-o-keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}
@-moz-keyframes spinTest {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spinTest {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-o-keyframes spinTest {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes spinTest {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
